import React, { useState } from 'react';
import {
    Layout,
    Typography,
    Result,
    Button,
    Row,
    Col,
    notification,
} from 'antd';
import { MailOutlined } from '@ant-design/icons';
import HeaderTop from '../../components/General/HeaderTop';
import RegisteForm from '../../components/General/RegisterForm';
import { resendEmailConfirmationApi } from '../../api/user';

import './Register.scss';

export default function Register() {
    const { Content } = Layout;
    const [showRegisterForm, setShowRegisterForm] = useState(true);
    const [email, setEmail] = useState('');
    const companyId = 'LaClotilde';

    return (
        <Layout className="register">
            <Content className="register__content">
                <HeaderTop
                    title={'Registro'}
                    backIconHandle={true}
                    goToUrl={'/login'}
                />

                {showRegisterForm && (
                    <RegisteForm
                        setShowRegisterForm={setShowRegisterForm}
                        setEmail={setEmail}
                        companyId={companyId}
                    />
                )}
                {!showRegisterForm && (
                    <EmailSend email={email} companyId={companyId} />
                )}
            </Content>
        </Layout>
    );
}

function EmailSend({ email, companyId }) {
    const handleResendEmail = async () => {
        const result = await resendEmailConfirmationApi(email, companyId);
        console.log(result);
        if (result.status === 200) {
            notification['success']({
                message: 'Correo enviado exitosamente.',
            });
        } else {
            notification['success']({
                message: 'Ocurrió un error, intentelo de nuevo.',
            });
        }
    };
    return (
        <div className="email-send">
            <Result
                icon={<MailOutlined />}
                title={<TitleResult />}
                subTitle={<SubTitleResult email={email} />}
                extra={
                    <Button
                        ghost
                        type="primary"
                        danger
                        onClick={handleResendEmail}
                    >
                        Reenviar email
                    </Button>
                }
            />
        </div>
    );
}

function TitleResult() {
    const { Title } = Typography;

    return (
        <Row>
            <Col span={24}>
                <Title level={2}>¡ Bienvenido !</Title>
                <Title level={3}>
                    {'Confirma tu dirección de correo electrónico'}
                </Title>
            </Col>
        </Row>
    );
}

function SubTitleResult({ email }) {
    const { Paragraph, Text } = Typography;

    return (
        <Row>
            <Col span={24}>
                <Paragraph type="secondary">
                    Te enviamos un correo electrónico de confirmacion a:
                </Paragraph>
                <Paragraph>
                    <Text strong>{email}</Text>
                </Paragraph>
                <Paragraph type="secondary">
                    {' '}
                    Por favor revisa tu correo y da click en el link de
                    confirmación para poder continuar.
                </Paragraph>
            </Col>
        </Row>
    );
}
